<template>

  <div v-if="bootstrapStore.isInit" class="bg-slate-100">
    <div
      class="relative border-slate-300 border mx-auto max-w-2xl flex flex-col w-full items-stretch border-b border-sand"
    >
    <div        
        class="flex items-center justify-between border-b border-gray-300 shadow border-marble h-16 bg-white"
      >
        <div class="flex flex-row items-center mx-4">
          <span class="text-lg text-gray-900 font-semibold">PhotoWish </span>
        </div>
        <div
          class="flex items-center mx-2 gap-x-2 relative flex-shrink-0"
        ></div>
      </div>

      <div class="min-h-screen pb-16">
        <slot />
      </div>     

  </div>
  </div>

  <div v-else-if="bootstrapStore.failed">
    <div class="flex items-center justify-center h-screen">
      <!-- <div class="text-2xl">Please access from LINE Offcial Account</div> -->
      <div class="text-2xl">Redirect To Line Login...</div>
    </div>
  </div>

  <div v-else>
    <div class="flex flex-col h-screen justify-between">
      <div class="flex items-center justify-center flex-grow">
        <div class="text-2xl">Loading...</div>
      </div>
      <div class="flex justify-center pb-4">
        <span class="text-gray-500 text-sm">version {{version}}</span>
      </div>
    </div>
  </div>

    <GlobalLoading />
</template>

<script setup>
import packageJson from '~/package.json'

const bootstrapStore = useBootstrapStore();
bootstrapStore.init();
const version = ref(packageJson.version)



</script>
